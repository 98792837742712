import { settingsPanelUrl } from './../../../services/settings';

export default {
  name: 'OrganizationSettings',
  components: {},
  data() {
    return {
      selectedTab: 'general',
      iframeUrl: ''
    };
  },
  watch: {
    '$route.params.section1': function () {
      this.buildIframeUrl();
    },
    '$route.params.section2': function () {
      this.buildIframeUrl();
    }
  },
  methods: {
    onTabChange(newTab) {
      this.selectedTab = newTab;
    },
    buildIframeUrl() {
      let section1 = this.$route.params.section1;
      let section2 = this.$route.params.section2;

      // let url = 'http://localhost:8081';
      let url = settingsPanelUrl;
      if (section1) {
        url += '/' + section1;
      }
      if (section2) {
        url += '/' + section2;
      }

      // Also put loginToken.
      // let loginToken = window.localStorage.getItem('growlyticsToken');
      // if (loginToken) {
      //   url += '?loginToken=' + loginToken;
      // }

      this.iframeUrl = url;
    }
  },

  mounted() {
    if (this.$store.state.token && this.$store.state.token.role != 'admin') {
      if (!(this.$store.state.token.email == 'ravi@growlytics.in' || this.$store.state.token.email == 'subhash@kenscio.com')) {
        location.href = '/';
        return;
      }
    }
    this.buildIframeUrl();
  },

  beforeDestroy() {}
};
